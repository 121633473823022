/**
 * @module bootstrapper
 * @description This small wrapper will force wepack to async load the actual
 * app and css (custom-elements.js). This bootstrapper will be exposed as 'app.js' without any chunk-hash and should not be
 * cached on the server, so we can use it to load new versions of web-components
 * using chunk-hashes from there on.
 */
async function init () {
  return import(/* webpackChunkName: "custom-elements" */'./custom-elements.js')
}

init()
